.website {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.website__container {
  z-index: 10;
  min-height: 100vh;
}

.website__title {
  color: white;
  text-align: center;
  font-size: 36pt;
  margin: 1rem 0;
  line-height: 50px;
}

.website__subtitle {
  color: white;
  text-align: center;
  font-size: 16pt;
  font-weight: 300;
  margin-top: 0.3rem;
}

.website__img {
  display: block;
  margin: 0 auto;
  width: 220px;
  border-radius: 50%;
  margin-top: 7%;
}

.website__icons__container {
  margin-top: 1.6rem;
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 1.4rem;
  left: 0;
}

.website__icon {
  color: #d3c14a;
  font-size: medium;
  margin: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.website__icon p {
  margin-left: 0.8rem;
  width: 0;
  overflow: hidden;
  transition: 0.5s;
  white-space: nowrap;
}

.website__icon:hover > .big {
  width: 240px;
  transition: 0.5s;
}

.website__icon:hover > .small {
  width: 100px;
  transition: 0.5s;
}

ion-icon {
  font-size: 32px;
}

.work__experience h3 {
  color: #d3c14a;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 0.8rem;
}

.work__experience__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.work__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 15rem;
}

.work__item img {
  height: 3.3rem;
}

.work__item a {
  color: #d3c14a;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
}

.work__item a p {
  margin-top: 0rem;
  opacity: 0;
  transition: 0.5s;
}

.work__item a:hover > p {
  opacity: 1;
}

.copy {
  font-size: small;
  opacity: .8;
  position: absolute;
  bottom: -2.4rem;
}

@media (max-width: 900px) {
  .website__img {
    width: 38%;
    margin-top: 15%;
  }

  .website__title {
    font-size: 28pt;
    line-height: 46px;
  }

  .work__experience__container {
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 10rem;
  }

  .website__icons__container {
    bottom: -20rem;
  }

  .website__icon {
    margin: 0 1rem;
  }

  .website__icon p {
    width: 0 !important;
  }
}
