@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #070806;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: white;
}

.bg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: .1;
  min-width: 100vw;
  min-height: 100vh;
  filter: blur(12px);
  background-image: url(./bg.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}